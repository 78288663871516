body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;

  overflow: hidden;
}

a {
  text-decoration: none;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Ubuntu";
  font-weight: 300;
  src: local("Ubuntu"), url(./fonts/Ubuntu-Light.ttf) format("opentype");
}

@font-face {
  font-family: "Ubuntu";
  font-weight: 400;

  src: local("Ubuntu"), url(./fonts/Ubuntu-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Ubuntu";
  font-weight: 500;
  src: local("Ubuntu"), url(./fonts/Ubuntu-Medium.ttf) format("opentype");
}

@font-face {
  font-family: "Ubuntu";
  font-weight: 900;
  src: local("Ubuntu"), url(./fonts/Ubuntu-Bold.ttf) format("opentype");
}
@font-face {
  font-family: "Ubuntu Mono";
  src: local("UbuntuMono"),
    url(./fonts/UbuntuMono-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Source Code Pro";
  src: local("SourceCodePro"),
    url(./fonts/SourceCodePro-VariableFont_wght.ttf) format("opentype");
}

.blob {
  filter: blur(100px);
  z-index: 0;
  transition: all 0.8s cubic-bezier(0, 1.08, 0.76, 0.99);
}
